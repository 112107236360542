<template>
  <div>
    <page-menu :links="links" :date="formatDate">
      <m-date-button :year="year" :month="month" :day="day" @prev="handlePrevDate" @next="handleNextDate" @select="handleSelectDate"></m-date-button>
    </page-menu>
    <div>
      <div style="padding-bottom: 2em" v-for="columnGroup in columnGroups" :key="columnGroup.id">
        <div class="ui header">{{ columnGroup.title }}</div>
        <div class="ui form">
          <table-data
            v-for="column in columnGroup.columns"
            :key="column.id"
            :column="column"
            :value="pickData(column.id)"
            :row="row"
            :year="year"
            :month="month"
            :day="day"
            @update="updateRowData"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TableService from "@/service/TableService"
import TableMixin from "@/mixins/TableMixin"
import TableData from "@/pages/TableDataForm/TableData"
import PageMenu from "@/components/common/PageMenu"
import MDateButton from "@/components/common/MDateButton"
export default {
  name: "TableDataForm",
  mixins: [TableMixin],
  components: { MDateButton, PageMenu, TableData },
  data() {
    return {
      links: [
        { title: "테이블 보기", to: { name: "TableListView", params: { id: this.$route.params.id } }, icon: "table" },
        { title: "달력으로 보기", to: { name: "TableMonthlyView", params: { id: this.$route.params.id } }, icon: "calendar" },
        { title: "리포트 보기", to: { name: "TableReportView", params: { id: this.$route.params.id } }, icon: "area chart" },
        { title: "테이블 설정", to: { name: "TableEditor", params: { id: this.$route.params.id } }, icon: "cog" },
        { title: "테이블 리스트", to: { name: "MyTables" }, icon: "th list" },
      ],
      columnGroups: [],
      row: {},
    }
  },
  async created() {
    const tableId = this.$route.params.id
    const {
      data: {
        data: { title, columnGroups },
      },
    } = await TableService.getMyTableWithSchema(tableId)
    // console.debug(title, columnGroups)
    this.$store.commit("page/changeTitle", title)
    this.columnGroups = columnGroups
    this.setDate()
    this.fetchData()
  },
  mounted() {},
  methods: {
    fetchData() {
      this.row = {}
      const tableId = this.$route.params.id
      TableService.getTableDataOneDay(tableId, this.year, this.month, this.day).then(({ data }) => {
        this.row = data.data
      })
    },
    updateRowData(columnId, value) {
      console.log(this.row[columnId], value)
      this.row[columnId] = { ...this.row[columnId], ...value }
      console.log(this.row[columnId], value)
    },
    pickData(columnId) {
      return this.row[columnId]
    },
    handlePrevDate() {
      this.setDate(this.prevDate)
      this.fetchData()
    },
    handleNextDate() {
      this.setDate(this.nextDate)
      this.fetchData()
    },
    handleSelectDate(selectedDate) {
      this.setDate(selectedDate)
      this.fetchData()
    },
  },
}
</script>

<style scoped></style>
