<template>
  <div class="ui icon primary buttons">
    <div class="ui icon button" @click="prev"><i class="ui caret left icon"></i></div>
    <div ref="calendar" class="ui calendar">
      <div id="date-text-button" class="ui button">{{ dateText }}</div>
    </div>
    <div class="ui icon button" @click="next"><i class="ui caret right icon"></i></div>
  </div>
</template>

<script>
import moment from "moment"
import { Weekdays } from "@/define/Weekday"

export default {
  name: "MDateButton",
  components: {},
  props: {
    type: { type: String, default: "date" },
    year: { type: Number, required: true },
    month: { type: Number, required: true },
    day: { type: Number, required: true },
  },
  computed: {
    monthIndex() {
      return this.month - 1
    },
    dateText() {
      if (this.year === 0) {
        return "Loading..."
      }
      if (this.type === "month") {
        return `${this.year}년 ${this.month}월`
      }
      return `${this.year}년 ${this.month}월 ${this.day}일 (${Weekdays[moment({ Y: this.year, M: this.monthIndex, D: this.day }).day()]})`
    },
  },
  watch: {
    year() {
      this.setDate()
    },
    month() {
      this.setDate()
    },
    day() {
      this.setDate()
    },
  },
  methods: {
    prev() {
      this.$emit("prev")
    },
    next() {
      this.$emit("next")
    },
    select(date) {
      const selectedDate = moment(date).format("YYYY-MM-DD")
      this.$emit("select", selectedDate)
    },
    setDate() {
      const calendar = this.$refs.calendar
      $(calendar).calendar("set date", moment({ Y: this.year, M: this.monthIndex, D: this.day }).toDate(), true, false)
    },
    initCalendar() {
      const calendar = this.$refs.calendar
      $(calendar).calendar({
        firstDayOfWeek: this.$store.state.settings.firstDayOfWeek,
        type: this.type,
        initialDate: moment({ Y: this.year, M: this.monthIndex, D: this.day }).toDate(),
        text: {
          months: ["1월", "2월", "3월", "4월", "5월", "6월", "7월", "8월", "9월", "10월", "11월", "12월"],
          monthsShort: ["1월", "2월", "3월", "4월", "5월", "6월", "7월", "8월", "9월", "10월", "11월", "12월"],
        },
        today: true,
        onChange: this.select,
      })
    },
  },
  mounted() {
    this.initCalendar()
  },
}
</script>

<style scoped lang="scss">
#date-text-button {
  border-radius: 0;
  box-sizing: initial;
}
</style>
