<template>
  <div>
    <div id="toolbar">
      <div class="ui icon button" :class="{ blue: isShowHiddenTables }" @click="isShowHiddenTables = !isShowHiddenTables">
        <i class="icon" :class="{ hide: !isShowHiddenTables, unhide: isShowHiddenTables, secondary: !isShowHiddenTables }"></i>
      </div>
      <div class="ui icon button" :class="{ blue: !isOrderLock }" @click="isOrderLock = !isOrderLock">
        <i class="move icon" :class="{ secondary: isOrderLock }"></i>
      </div>
    </div>
    <div class="clearfix"></div>
    <div style="padding-bottom: 100px">
      <draggable
        class="card-container"
        ghost-class="ghost"
        animation="100"
        :disabled="isOrderLock"
        v-model="tables"
        group="table"
        @start="drag = true"
        @end="drag = false"
        @change="changeOrders"
      >
        <transition-group type="transition" name="flip-list">
          <table-item
            v-for="table in tables"
            :key="table.id"
            :table="table"
            :move="!isOrderLock"
            v-show="isShowHiddenTables || !table.meta.hidden"
            @remove="fetchTables"
          ></table-item>
        </transition-group>
      </draggable>
    </div>
    <m-fab @click="createTableForm" popup-text="여기를 눌러 새로운 테이블을 추가하세요" :showPopup="popupInStart"></m-fab>
    <div class="ui bottom fixed menu"></div>
  </div>
</template>

<script>
import TableService from "/src/service/TableService"
import MFab from "@/components/common/MFab"
import PageMixin from "@/mixins/PageMixin"
import TableItem from "@/pages/MyTables/TableItem"
import draggable from "vuedraggable"
import lodash from "lodash"
import { Page } from "@/define/Page"
export default {
  name: "MyTables",
  components: {
    MFab,
    TableItem,
    draggable,
  },
  mixins: [PageMixin],
  data: () => {
    return {
      $pageTitle: "MOON TABLE",
      tables: [],
      drag: false,
      isShowHiddenTables: false,
      isOrderLock: true,
      popupInStart: false,
    }
  },
  computed: {
    orders() {
      return lodash.map(this.tables, "id")
    },
  },
  methods: {
    createTableForm() {
      this.$router.push({ name: "NewTableForm" })
    },
    fetchTables() {
      TableService.getMyTables().then(({ data }) => {
        // console.debug(data.list)
        this.tables = data.list
        this.popupInStart = this.tables.length === 0
      })
    },
    changeOrders() {
      // evt
      // console.debug(evt.moved)
      TableService.changeTableOrders(this.orders)
    },
  },
  created() {
    this.$store.commit("page/changeTitle", Page.MyTables.title)
  },
  mounted() {
    this.fetchTables()
  },
}
</script>

<style scoped lang="scss">
#toolbar {
  margin-bottom: 10px;
  float: right;
}
.ghost {
  opacity: 0.5;
  background: #c8ebfb;
}
</style>
