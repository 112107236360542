<template>
  <div class="field" :class="{ error: !valid }">
    <label>{{ label }}</label>
    <div><m-action-text-input v-model="newTask" action-icon="plus" @action="addTask" placeholder="작업을 추가하세요"></m-action-text-input></div>
    <div class="ui selection small list" v-if="tasks.length > 0">
      <task v-for="task in tasks" :key="task.id" :task="task" @update="update" @remove="remove"></task>
    </div>
    <div class="ui basic small segment" v-else>작업이 없습니다.</div>
  </div>
</template>

<script>
import MActionTextInput from "@/components/common/MActionTextInput"
import TableService from "@/service/TableService"
import Task from "@/components/MoonTableColumnTypes/MListTask/Task"
import lodash from "lodash"
export default {
  name: "MListTaskForm",
  components: { Task, MActionTextInput },
  props: {
    column: {
      type: Object,
      required: true,
    },
    value: Array,
    label: String,
    valid: Boolean,
  },
  data() {
    return {
      newTask: "",
      tasks: [],
    }
  },
  watch: {
    value(value) {
      this.tasks = value ?? []
    },
  },
  computed: {
    // tasks() {
    //   return this.value ?? []
    // },
  },
  methods: {
    async addTask(value) {
      console.log("ADD", value)

      if (!value) return

      const { id: columnId, columnGroupId, tableId } = this.column
      const item = await TableService.addItem(tableId, columnGroupId, columnId, value)
      this.tasks.push(item)
      this.$emit("addTask", item)

      this.newTask = null
    },
    update(task) {
      this.$emit("update", task)
    },
    remove(task) {
      this.tasks = lodash.reject(this.tasks, { id: task.id })
      this.$emit("remove", task)
    },
  },
}
</script>

<style scoped></style>
