<template>
  <fragment>{{ numberValue }}</fragment>
</template>

<script>
import numeral from "numeral"
export default {
  name: "MNumberCell",
  props: {
    column: Object,
    cell: Object,
  },
  computed: {
    numberValue() {
      if (!this.cell?.numberValue && this.cell?.numberValue !== 0) {
        return ""
      }
      const num = numeral(this.cell.numberValue)
      if (this.$store.state.settings.formatNumber) {
        return num.format("0,0[.]00")
      }
      return num.value()
    },
  },
}
</script>

<style scoped></style>
