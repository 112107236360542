<template>
  <div>Report View</div>
</template>

<script>
import TableMixin from "@/mixins/TableMixin"
import TableService from "@/service/TableService"

export default {
  name: "TableReportView",
  mixins: [TableMixin],
  async created() {
    const tableId = this.$route.params.id

    const res = await TableService.getMyTableWithSchema(tableId)

    const {
      data: {
        data: { title, columnGroups },
      },
    } = res
    this.$store.commit("page/changeTitle", title)
    this.columnGroups = columnGroups
    this.setDate()
    this.fetchData()
  },
  methods: {
    fetchData() {},
  },
}
</script>

<style scoped></style>
