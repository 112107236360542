import Vue from "vue"
import Vuex from "vuex"
import createPersistedState from "vuex-persistedstate"
import user from "./modules/user"
import page from "./modules/page"
import settings from "./modules/settings"
Vue.use(Vuex)

const debug = process.env.NODE_ENV !== "production"

export default new Vuex.Store({
  state: {
    token: null,
  },
  modules: {
    user,
    page,
    settings,
  },
  mutations: {
    setToken(state, token) {
      state.token = token
    },
  },
  plugins: [createPersistedState({ paths: ["token", "user"] })],
  strict: debug,
})
