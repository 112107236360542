<template>
  <fragment>{{ textValue }}</fragment>
</template>

<script>
export default {
  name: "MTableCellTextPlain",
  props: {
    column: Object,
    cell: Object,
  },
  computed: {
    textValue() {
      return this.cell?.textValue
    },
  },
}
</script>

<style scoped></style>
