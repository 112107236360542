<template>
  <div class="field" :class="{ error: !valid, icon: icon, inline: inline }">
    <label>{{ label }}</label>
    <div class="ui selection dropdown" ref="dropdown" :class="{ disabled }">
      <input type="hidden" name="dropdown" :value="value" />
      <i class="dropdown icon"></i>
      <div class="default text">{{ placeholder }}</div>
      <div class="scrollhint menu">
        <div class="item" :data-value="item.value" v-for="item in activeItems" :key="item.value">
          <i class="icon" :class="[item.icon, { active: item.value === value, selected: item.value === value }]" v-if="icon && item.icon"></i>
          {{ item.name }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import lodash from "lodash"
export default {
  name: "MFieldDropdown",
  props: {
    disabled: Boolean,
    icon: Boolean,
    value: [String, Number, Object],
    label: String,
    placeholder: String,
    valid: Boolean,
    items: Array,
    inline: Boolean,
  },
  watch: {
    value() {
      this.init()
    },
  },
  computed: {
    activeItems() {
      return lodash.filter(this.items, { active: true })
    },
  },
  methods: {
    init() {
      this.$nextTick(() => {
        console.log("dropdown init", this.value)
        $(this.$refs.dropdown).dropdown({
          showOnFocus: false,
          onChange: (value, text, $choice) => {
            this.$emit("input", value, text, $choice)
            this.$emit("update", value, text, $choice)
          },
        })
      })
    },
    // change(evt) {
    //   this.$emit("input", evt.target.value)
    // },
  },
  mounted() {
    this.init()
  },
}
</script>

<style scoped></style>
