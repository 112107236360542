<template>
  <div class="ui action input">
    <m-text-input :placeholder="placeholder" :value="v" @input="input" @enter="handleAction" />
    <slot name="action">
      <button class="ui button" :class="{ icon: isActionIcon }" @click="handleAction">
        <i class="ui icon" :class="[actionIcon]" v-if="isActionIcon"></i>
        {{ actionText }}
      </button>
    </slot>
  </div>
</template>

<script>
import MTextInput from "@/components/common/MTextInput"
export default {
  name: "MActionTextInput",
  components: { MTextInput },
  props: {
    value: {
      type: String,
    },
    placeholder: {
      type: String,
    },
    actionText: { type: String },
    actionIcon: {
      type: String,
    },
  },
  data() {
    return {
      v: this.value,
    }
  },
  watch: {
    value(value) {
      this.v = value
    },
  },
  computed: {
    isActionIcon() {
      return !!this.actionIcon
    },
  },
  methods: {
    handleAction() {
      this.$emit("action", this.v)
    },
    input(value) {
      this.$emit("input", value)
    },
  },
}
</script>

<style scoped></style>
