<template>
  <fragment>
    <tr>
      <td class="col-stat">Count</td>
      <td class="col-stat" v-for="(count, index) in counts" :key="index">{{ count | formatNumber }}</td>
    </tr>

    <tr v-show="showMore && showSums">
      <td class="col-stat">Sum</td>
      <td class="col-stat" v-for="(sum, index) in sums" :key="index">{{ sum | formatNumber }}</td>
    </tr>
    <tr v-show="showMore && showAverages">
      <td class="col-stat">Avg</td>
      <td class="col-stat" v-for="(avg, index) in averages" :key="index">{{ avg | formatNumber }}</td>
    </tr>
    <tr v-show="showMore && showMedians">
      <td class="col-stat">Med</td>
      <td class="col-stat" v-for="(median, index) in medians" :key="index">{{ median | formatNumber }}</td>
    </tr>
    <tr v-show="showMore && showMinimums">
      <td class="col-stat">Min</td>
      <td class="col-stat" v-for="(min, index) in minimums" :key="index">{{ min | formatNumber }}</td>
    </tr>
    <tr v-show="showMore && showMaximums">
      <td class="col-stat">Max</td>
      <td class="col-stat" v-for="(max, index) in maximums" :key="index">{{ max | formatNumber }}</td>
    </tr>
    <tr v-show="showMore && showItems">
      <td class="col-stat">Items</td>
      <td class="col-stat" v-for="(item, index) in items" :key="index">{{ item | formatNumber }}</td>
    </tr>
    <tr v-show="showMore && showCompletes">
      <td class="col-stat">Done</td>
      <td class="col-stat" v-for="(completed, index) in completes" :key="index">{{ completed | formatNumber }}</td>
    </tr>
    <tr v-show="showMore && showSumItems">
      <td class="col-stat">Sum Items</td>
      <td class="col-stat" v-for="(sumItem, index) in sumItems" :key="index">{{ sumItem | formatNumber }}</td>
    </tr>
    <tr @click="showMore = !showMore" id="row-stat-expand">
      <td class="col-stat">
        <!--        <i class="calculator icon"> </i>-->
        <i v-show="showMore" class="compress alternate icon"></i>
        <i v-show="!showMore" class="expand alternate icon"></i>
      </td>
      <td class="col-stat" v-for="(count, index) in counts" :key="index"></td>
    </tr>
  </fragment>
</template>

<script>
import { median } from "@/utils/Stat"

const EMPTY = null

import lodash from "lodash"
import { formatNumber } from "@/filters"

export default {
  name: "TableStatRows",
  props: {
    columns: { type: Array, default: () => [] },
    rows: { type: Object, default: () => ({}) },
  },
  filters: {
    formatNumber,
  },
  data() {
    return {
      showMore: false,
    }
  },
  computed: {
    rowValues() {
      return lodash.values(this.rows)
    },
    counts() {
      const result = []
      this.columns.forEach((col, index) => {
        result[index] = 0
        const { id, type } = col
        this.rowValues.forEach((row) => {
          switch (type) {
            case "TEXT_PLAIN":
              result[index] += row[id]?.textValue ? 1 : 0
              break
            case "NUMBER":
              result[index] += row[id]?.numberValue || row[id]?.numberValue === 0 ? 1 : 0
              break
            case "CHECKBOX":
              result[index] += row[id]?.checked ? 1 : 0
              break
            case "SELECT_SINGLE":
            case "SELECT_MULTI":
            case "LIST_SIMPLE":
            case "LIST_TASK":
            case "LIST_TIME":
            case "LIST_NUMBER":
              result[index] += row[id]?.items.length ? 1 : 0
              break
            default:
              result[index] = EMPTY
          }
        })
      })
      return result
    },
    showItems() {
      return this.showStat(this.columns, ["SELECT_SINGLE", "SELECT_MULTI", "LIST_SIMPLE", "LIST_TASK", "LIST_TIME", "LIST_NUMBER"])
    },
    items() {
      const result = []
      this.columns.forEach((col, index) => {
        result[index] = 0
        const { id, type } = col
        this.rowValues.forEach((row) => {
          switch (type) {
            case "SELECT_SINGLE":
            case "SELECT_MULTI":
            case "LIST_SIMPLE":
            case "LIST_TASK":
            case "LIST_TIME":
            case "LIST_NUMBER":
              result[index] += row[id]?.items.length ? row[id].items.length : 0
              break
            default:
              result[index] = EMPTY
          }
        })
      })
      return result
    },
    showCompletes() {
      return this.showStat(this.columns, ["LIST_TASK"])
    },
    completes() {
      const result = []
      this.columns.forEach((col, index) => {
        result[index] = 0
        const { id, type } = col
        this.rowValues.forEach((row) => {
          switch (type) {
            case "LIST_TASK":
              result[index] += lodash.filter(row[id]?.items ?? [], { checked: true }).length
              break
            default:
              result[index] = EMPTY
          }
        })
      })
      return result
    },
    showSumItems() {
      return this.showStat(this.columns, ["LIST_NUMBER"])
    },
    sumItems() {
      const result = []
      this.columns.forEach((col, index) => {
        if (this.items[index] === 0) {
          result[index] = EMPTY
          return
        }
        result[index] = 0
        const { id, type } = col
        this.rowValues.forEach((row) => {
          switch (type) {
            case "LIST_NUMBER":
              result[index] += lodash
                .chain(row[id]?.items ?? [])
                .filter((item) => item?.numberValue === 0 || item?.numberValue)
                .map((item) => item.numberValue)
                .sum()
                .value()
              break
            default:
              result[index] = EMPTY
          }
        })
      })
      return result
    },
    showSums() {
      return this.showStat(this.columns, ["NUMBER"])
    },
    sums() {
      const result = []
      this.columns.forEach((col, index) => {
        result[index] = 0
        const { id, type } = col
        const values = this.numberValues(id)
        switch (type) {
          case "NUMBER":
            result[index] = values.length ? lodash.sum(values) : EMPTY
            break
          default:
            result[index] = EMPTY
        }
      })
      return result
    },
    showAverages() {
      return this.showStat(this.columns, ["NUMBER"])
    },
    averages() {
      const result = []
      this.columns.forEach((col, index) => {
        result[index] = 0
        const { id, type } = col
        const values = this.numberValues(id)
        switch (type) {
          case "NUMBER":
            result[index] = values.length ? lodash.mean(values) : EMPTY
            break
          default:
            result[index] = EMPTY
        }
      })
      return result
    },
    showMedians() {
      return this.showStat(this.columns, ["NUMBER"])
    },
    medians() {
      const result = []
      this.columns.forEach((col, index) => {
        result[index] = 0
        const { id, type } = col
        const values = this.numberValues(id)
        switch (type) {
          case "NUMBER":
            result[index] = values.length ? median(values) : EMPTY
            break
          default:
            result[index] = EMPTY
        }
      })
      return result
    },
    showMinimums() {
      return this.showStat(this.columns, ["NUMBER"])
    },
    minimums() {
      const result = []
      this.columns.forEach((col, index) => {
        result[index] = 0
        const { id, type } = col
        const values = this.numberValues(id)
        switch (type) {
          case "NUMBER":
            result[index] = values.length ? lodash.min(this.numberValues(id)) : EMPTY

            break
          default:
            result[index] = EMPTY
        }
      })
      return result
    },
    showMaximums() {
      return this.showStat(this.columns, ["NUMBER"])
    },
    maximums() {
      const result = []
      this.columns.forEach((col, index) => {
        result[index] = 0
        const { id, type } = col
        const values = this.numberValues(id)
        switch (type) {
          case "NUMBER":
            result[index] = values.length ? lodash.max(this.numberValues(id)) : EMPTY

            break
          default:
            result[index] = EMPTY
        }
      })
      return result
    },
  },
  methods: {
    numberValues(id) {
      return lodash
        .chain(this.rowValues)
        .map((row) => row[id]?.numberValue)
        .filter((numberValue) => numberValue || numberValue === 0)
        .value()
    },
    showStat(columns, types) {
      return lodash.find(columns, ({ type }) => {
        return lodash.find(types, (t) => t === type)
      })
    },
  },
  mounted() {
    // console.log("COLUMNS", this.columns)
    // console.log("ROWS", this.rows)
  },
}
</script>

<style scoped lang="scss">
#row-stat-expand {
  cursor: pointer;
  & td {
    text-align: left;
  }
}
</style>
