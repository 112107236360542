<template>
  <div>
    <p>페이지를 찾을 수 없습니다.</p>
  </div>
</template>

<script>
export default {
  name: "NotFound",
  created() {
    this.$store.commit("page/changeTitle", "MOON TABLE")
  },
}
</script>

<style scoped></style>
