import axios from "axios"
import store from "@/store"
import { toast, notice } from "@/utils/SemanticUtil"
import { logoutUser } from "@/service/AuthService"
function createInstance() {
  const instance = axios.create()
  return setInterceptors(instance, false)
}

export const instance = createInstance()

function createInstanceWithAuth() {
  const instance = axios.create()
  return setInterceptors(instance, true)
}

export const instanceWithAuth = createInstanceWithAuth()

function setInterceptors(instance, withAuth) {
  instance.interceptors.request.use(
    function (config) {
      // Do something before request is sent
      if (withAuth && store.state.token) {
        config.headers.Authorization = "Bearer " + store.state.token
      }

      store.commit("page/onLoading")

      // console.debug("REQ", config.options)

      if (config.options && config.options.loader) {
        store.commit("page/onLoader")
      }

      if (config.options && config.options.progress) {
        store.commit("page/onProgress")
      }

      return config
    },
    function (error) {
      // Do something with request error
      return Promise.reject(error)
    }
  )

  instance.interceptors.response.use(
    function (response) {
      // Any status code that lie within the range of 2xx cause this function to trigger
      // Do something with response data
      store.commit("page/offLoading")

      // console.debug("RES", response.config.options)

      if (response.config.options && response.config.options.loader) {
        store.commit("page/offLoader")
      }

      if (response.config.options && response.config.options.progress) {
        store.commit("page/offProgress")
      }

      if (response.config.options && response.config.options.toast && response.config.options.successText) {
        toast(response.config.options.successText)
      }

      if (response.config.options && response.config.options.notice && response.config.options.successText) {
        notice(response.config.options.successText)
      }

      return response
    },
    function (error) {
      // Any status codes that falls outside the range of 2xx cause this function to trigger
      // Do something with response error

      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        console.log(error.response.data)
        console.log(error.response.status)
        console.log(error.response.headers)

        if (error.response.status === 401) {
          logoutUser()
        }
      } else if (error.request) {
        // The request was made but no response was received
        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
        // http.ClientRequest in node.js
        console.log(error.request)
      } else {
        // Something happened in setting up the request that triggered an Error
        console.log("Error", error.message)
      }
      console.log(error.config)

      store.commit("page/offLoading")

      if (error.config.options && error.config.options.loader) {
        store.commit("page/offLoader")
      }

      if (error.config.options && error.config.options.progress) {
        store.commit("page/offProgress")
      }

      if (error.config.options && error.config.options.toast) {
        toast(error.config.options.errorText || "ERROR", "error")
      }

      if (error.config.options && error.config.options.notice) {
        notice(error.config.options.errorText || "ERROR", "error")
      }

      return Promise.reject(error)
    }
  )

  return instance
}
