<template>
  <div class="field" :class="{ error: !valid }">
    <label><i class="grey icon" :class="icon"></i>{{ label }}</label>
    <div class="ui left input">
      <input type="number" inputmode="decimal" :value="numberValue" @keyup="changeInput" @keypress.enter="update" @blur="update" />
    </div>
  </div>
</template>

<script>
import DataInputMixin from "@/mixins/DataInputMixin"
import { findType } from "@/define/ColumnTypes"
export default {
  name: "MNumber",
  mixins: [DataInputMixin],
  props: {
    id: [String, Number],
    value: [String, Number],
    label: String,
    valid: Boolean,
  },
  computed: {
    icon() {
      return findType("NUMBER").icon
    },
    numberValue() {
      return this.value
    },
  },
  methods: {
    changeInput(evt) {
      this.$emit("input", evt.target.value)
    },
  },
}
</script>

<style scoped></style>
