<template>
  <div id="moon-card" class="ui fluid card" :class="{ move }">
    <div class="content">
      <i class="grey right floated link sticky note icon" @click="showNote" v-if="hasNote"></i>
      <i class="grey right floated hide icon" v-if="isHidden"></i>
      <div class="header">
        <router-link tag="span" class="title" :to="{ name: 'TableListView', params: { id: table.id } }">
          {{ table.title }}
        </router-link>
      </div>
      <div class="meta" v-if="table.meta.summary && summary.loaded">
        <span>{{ summary.text }} : {{ summary.currentCount }} / {{ summary.totalCount }}</span>
      </div>
    </div>

    <div class="extra content">
      <div class="toolbar">
        <div class="ui icon large buttons">
          <!--
        <div class="ui primary button" @click="isToolbarOpen = !isToolbarOpen"><i class="angle tertiary icon" :class="{ left: !isToolbarOpen, right: isToolbarOpen }"></i></div>
        -->
          <router-link class="ui tertiary button" :to="{ name: 'TableDataForm', params: { id: table.id } }"><i class="pencil icon"></i></router-link>
          <router-link class="ui tertiary button" :to="{ name: 'TableListView', params: { id: table.id } }"><i class="table icon"></i></router-link>
          <router-link class="ui tertiary button" :to="{ name: 'TableMonthlyView', params: { id: table.id } }"><i class="calendar icon"></i></router-link>
          <router-link class="ui tertiary button" :to="{ name: 'TableReportView', params: { id: table.id } }"><i class="area chart icon"></i></router-link>
          <router-link class="ui tertiary button" :to="{ name: 'TableEditor', params: { id: table.id } }"><i class="cog icon"></i></router-link>
        </div>
      </div>
    </div>
    <div class="clearfix"></div>
    <m-dialog mini v-model="showDialog" :headerText="table.title">
      <div>
        <pre>{{ table.description }}</pre>
      </div>
    </m-dialog>
  </div>
</template>

<script>
import lodash from "lodash"
import MDialog from "@/components/common/MDialog"
import TableService from "@/service/TableService"
export default {
  name: "TableItem",
  components: { MDialog },
  props: {
    table: {
      type: Object,
      required: true,
    },
    move: {
      type: Boolean,
      default: false,
    },
  },
  data: () => {
    return {
      isToolbarOpen: true,
      showDialog: false,
      summary: {
        loaded: false,
        text: null,
        currentCount: 0,
        totalCount: 0,
      },
    }
  },
  computed: {
    isHidden() {
      return this.table.meta.hidden
    },
    hasNote() {
      return lodash.trim(this.table.description)
    },
  },
  methods: {
    showNote() {
      this.showDialog = true
    },

    fetchSummary() {
      TableService.getTableSummary(this.table.id).then((summary) => {
        this.summary.text = summary.text
        this.summary.currentCount = summary.currentCount
        this.summary.totalCount = summary.totalCount
        this.summary.loaded = true
      })
    },
  },
  mounted() {
    if (this.table.meta.summary) {
      this.fetchSummary()
    }
  },
}
</script>

<style scoped lang="scss">
#moon-card {
  //padding: 3em 1em;
  //border-top: 1px solid $moon-bd-color;

  &.move {
    cursor: move;
  }

  .title {
    cursor: pointer;
    font-family: RIDIBatang;
    //font-family: KoreanFrenchTypewriter;
    //font-family: Restart;
    //font-family: Dajin;
    //font-family: SDMiSaeng;

    //font-weight: normal;
  }

  .toolbar {
    display: block;
    float: right;
  }
}
</style>
