<template>
  <div class="field" :class="{ error: !valid }">
    <label>{{ label }}</label>
    <div><m-action-text-input v-model="newItem" action-icon="plus" @action="addItem" placeholder="항목을 추가하세요"></m-action-text-input></div>
    <div class="ui selection small list" v-if="items.length > 0">
      <item v-for="item in items" :key="item.id" :item="item" @update="update" @remove="remove" @move="move" @copy="copy"></item>
    </div>
    <div class="ui basic small segment" v-else>항목이 없습니다.</div>
  </div>
</template>

<script>
import MActionTextInput from "@/components/common/MActionTextInput"
import Item from "@/components/MoonTableColumnTypes/MListSimple/Item"
import TableService from "@/service/TableService"
import lodash from "lodash"

export default {
  name: "MListSimpleForm",
  components: { Item, MActionTextInput },
  props: {
    column: {
      type: Object,
      required: true,
    },
    value: Array,
    label: String,
    valid: Boolean,
  },
  data() {
    return {
      newItem: "",
      items: [],
    }
  },
  watch: {
    value(value) {
      this.items = value ?? []
    },
  },
  methods: {
    async addItem(value) {
      console.log("ADD", value)

      if (!value) return

      const { id: columnId, columnGroupId, tableId } = this.column
      const item = await TableService.addItem(tableId, columnGroupId, columnId, value)
      this.items.push(item)
      this.$emit("addItem", item)

      this.newItem = null
    },
    update(item) {
      // 변경된 항목으로 교체
      // 서버 데이터는 이미 변경 됨
      const index = lodash.findIndex(this.items, (i) => i.id === item.id)
      this.items[index].title = item.title
    },
    remove(item) {
      this.items = lodash.reject(this.items, { id: item.id })
      this.$emit("remove", item)
    },
    move(item, targetDate) {
      this.items = lodash.reject(this.items, { id: item.id })
      console.log("MOVE", targetDate, item)
      this.$emit("move", item, targetDate)
    },
    copy(item, targetDate) {
      console.log("COPY", targetDate, item)
      this.$emit("copy", item, targetDate)
    },
  },
}
</script>

<style scoped></style>
