const state = () => ({
  nickname: null,
  userId: null,
  loggedIn: false,
})

// getters
const getters = {}

// actions
const actions = {}

// mutations
const mutations = {
  login(state, moonUser) {
    state.nickname = moonUser.nickname
    state.userId = moonUser.id
    state.loggedIn = true
  },
  logout(state) {
    state.nickname = null
    state.userId = null
    state.loggedIn = false
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
