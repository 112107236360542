<template>
  <div>
    <page-menu :links="links" :date="formatDate">
      <m-date-button type="month" :year="year" :month="month" :day="day" @prev="movePrevMonth" @next="moveNextMonth" @select="selectMonth"></m-date-button>
    </page-menu>

    <div id="table-wrapper">
      <table id="moon-table-list" class="">
        <thead>
          <tr>
            <th rowspan="2" class="col-day">일자</th>
            <th v-for="columnGroup in columnGroups" class="column-group" :key="columnGroup.id" :colspan="columnGroup.columns.length">
              <i class="ui minus grey icon"></i> {{ columnGroup.title }}
            </th>
          </tr>
          <tr>
            <th class="col-data" v-for="column in columns" :key="column.id"><i class="ui grey icon" :class="getColumnIcon(column)"></i>{{ column.title }}</th>
          </tr>
        </thead>
        <tbody v-if="!loaded">
          <tr>
            <td class="col-day"></td>
            <td :colspan="columns.length" class="loading"><i class="spinner loading icon"></i>Loading...</td>
          </tr>
        </tbody>
        <tbody v-if="loaded">
          <table-data-row v-for="(row, dt) in rows" :table-id="tableId" :key="dt" :dt="dt" :row="row" :columns="columns" />
          <table-stat-rows :columns="columns" :rows="rows"></table-stat-rows>
        </tbody>
      </table>
    </div>
    <div class="padding"></div>
  </div>
</template>

<script>
import TableService from "@/service/TableService"
import TableMixin from "@/mixins/TableMixin"
import lodash from "lodash"
import TableDataRow from "@/pages/TableListView/TableDataRow"
import { findType } from "@/define/ColumnTypes"
import PageMenu from "@/components/common/PageMenu"
import MDateButton from "@/components/common/MDateButton"
import TableStatRows from "@/pages/TableListView/TableStatRows"
export default {
  name: "TableListView",
  components: {
    TableStatRows,
    MDateButton,
    PageMenu,
    TableDataRow,
  },
  mixins: [TableMixin],
  data() {
    return {
      loaded: false,
      columnGroups: [],
      dtDataMap: {},
      links: [
        { title: "달력으로 보기", to: { name: "TableMonthlyView", params: { id: this.$route.params.id } }, icon: "calendar" },
        { title: "리포트 보기", to: { name: "TableReportView", params: { id: this.$route.params.id } }, icon: "area chart" },
        { title: "테이블 설정", to: { name: "TableEditor", params: { id: this.$route.params.id } }, icon: "cog" },
        { title: "테이블 리스트", to: { name: "MyTables" }, icon: "th list" },
      ],
    }
  },
  computed: {
    columns() {
      return lodash.flatten(lodash.map(this.columnGroups, "columns"))
    },
    rows() {
      return this.dtDataMap
    },
    tableId() {
      return this.$route.params.id
    },
    loading() {
      return this.$store.state.page.loading || lodash.isEmpty(this.dtDataMap)
    },
  },
  async created() {
    const tableId = this.$route.params.id

    const res = await TableService.getMyTableWithSchema(tableId)

    const {
      data: {
        data: { title, columnGroups },
      },
    } = res
    this.$store.commit("page/changeTitle", title)
    this.columnGroups = columnGroups
    this.setDate()
    this.fetchData().then(() => {
      this.loaded = true
    })
  },
  watch: {},
  methods: {
    fetchData() {
      const tableId = this.$route.params.id
      return TableService.getTableData(tableId, this.year, this.month).then(({ data }) => {
        this.dtDataMap = data.data
      })
    },
    movePrevMonth() {
      this.setDate(this.prevMonth)
      this.fetchData()
    },
    moveNextMonth() {
      this.setDate(this.nextMonth)
      this.fetchData()
    },
    selectMonth(selectedDate) {
      this.setDate(selectedDate)
      this.fetchData()
    },

    getColumnIcon(column) {
      return findType(column.type).icon
    },
  },
}
</script>

<style lang="scss">
.padding {
  padding: 3em;
}
#toolbar {
  margin-bottom: 10px;
  float: right;
}
#table-wrapper {
  overflow-x: scroll;
  //margin-left: -1em;
  //margin-right: -1em;
}
#moon-table-list {
  min-width: 100%;
  //border-spacing: 0;
  border-collapse: collapse;

  th,
  td {
    &.col-day {
      text-align: right;
      width: 60px;
      min-width: 60px;
      max-width: 60px;
      padding: 0.3em 0.5em;
      //padding-right: 10px;
      background-color: #00000007;
    }
    &.col-data {
      min-width: 80px;
    }
    &.col-stat {
      color: #00000088;
      padding: 0.3em 0.5em;
      text-align: right;
      background-color: rgba(5, 36, 64, 0.04);
    }
  }

  th {
    background-color: #00000010;
    //border: solid 1px $moon-bd-color;
    text-align: left;
    padding: 0.1em 0.3em;
    &.column-group {
      background-color: #00000007;
    }
    &.col-day {
      background-color: #00000007;
    }
  }
  td {
    border-bottom: solid 1px #00000010;
    //text-align: left;
    padding: 0.3em;
    height: 30px;

    &.loading {
      text-align: left;
      padding-left: 1em;
    }
  }
  .today {
    td {
      background-color: #1da1f220;
    }
  }
  .sunday {
    td.col-day {
      background-color: #ff5a6320;
    }
  }
}
</style>
