<template>
  <div class="ui active inverted dimmer" v-if="show">
    <div class="ui loader"></div>
  </div>
</template>

<script>
export default {
  name: "MLoader",
  computed: {
    show() {
      return this.$store.state.page.loader
    },
  },
}
</script>

<style scoped></style>
