<template>
  <fragment>
    <div class="ui read-only checkbox" :class="{ checked: checked }">
      <input type="checkbox" :checked="checked" />
      <label></label>
    </div>
  </fragment>
</template>

<script>
export default {
  name: "MTableCellCheckBox",
  props: {
    column: Object,
    cell: Object,
  },
  computed: {
    checked() {
      return this.cell?.checked
    },
  },
}
</script>

<style scoped></style>
