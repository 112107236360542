export function toast(message, type = "success") {
  $("body").toast({
    position: "bottom center",
    class: type,
    message: message,
  })
}

export function notice(message, type = "success") {
  $("body").toast({
    position: "top attached",
    class: type,
    message: message,
    transition: {
      showMethod: "fly down",
      showDuration: 500,
      hideMethod: "fade up",
      hideDuration: 500,
    },
  })
}

export function $alert(content, title = undefined) {
  $("body")
    .modal({
      title: title,
      class: "mini",
      closeIcon: true,
      allowMultiple: false,
      content: content,
      actions: [
        {
          icon: "check",
          text: "확인",
          class: "primary",
        },
      ],
    })
    .modal("show")
}

export function $confirm(content, title = undefined, onApprove = () => {}, onDeny = () => {}) {
  $("body")
    .modal({
      title: title,
      content: content,
      class: "mini",
      closeIcon: false,
      closable: false,
      transition: {
        showMethod: "fade up",
        hideMethod: "fade down",
      },
      actions: [
        {
          text: "취소",
          class: "secondary",
          click: onDeny,
        },
        {
          icon: "check",
          text: "확인",
          class: "primary",
          click: onApprove,
        },
      ],
    })
    .modal("show")
}
