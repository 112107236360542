<template>
  <div class="page-menu">
    <div id="toolbar" class="ui icon buttons">
      <router-link
        v-for="(link, index) in links"
        :key="index"
        class="ui icon tertiary button popup-button"
        :to="{ ...link.to, query: { date: date } }"
        :data-content="link.title"
      >
        <i class="icon" :class="[link.icon]"></i>
      </router-link>
    </div>
    <div>
      <slot></slot>
    </div>
    <div class="clearfix"></div>
  </div>
</template>

<script>
export default {
  name: "PageMenu",
  props: {
    links: Array,
    date: String,
  },
  mounted() {
    $(".popup-button").popup({ position: "top center" })
  },
}
</script>

<style scoped></style>
