<template>
  <div id="app">
    <moon-header></moon-header>
    <main class="ui container">
      <router-view></router-view>
    </main>
    <m-loader></m-loader>
    <m-progress></m-progress>
  </div>
</template>

<script>
import "@/styles/app.scss"
import MoonHeader from "@/components/common/MoonHeader"
import MProgress from "@/components/common/MProgress"
import MLoader from "@/components/common/MLoader"
export default {
  name: "App",
  components: {
    MLoader,
    MoonHeader,
    MProgress,
  },
}
</script>

<style></style>
