<template>
  <div id="wrapper" class="ui center aligned basic segment">
    <div id="kakao-login"></div>
    <div id="demo-login" class="ui big button" @click="demoLogin">DEMO LOGIN</div>
  </div>
</template>

<script>
import { Page } from "@/define/Page"
import { loginUser } from "@/service/AuthService"
export default {
  name: "Login",
  created() {
    this.$store.commit("page/changeTitle", Page.Login.title)
  },
  mounted() {
    Kakao.Auth.createLoginButton({
      container: "#kakao-login",
      success: this.getKakaoAccount,
      fail: function (err) {
        // TODO login fail
        alert(JSON.stringify(err))
      },
    })
  },
  methods: {
    getKakaoAccount(authObj) {
      // console.log(authObj)
      const kakaoAccessToken = authObj.access_token
      // console.debug("KAKAO ACCESS TOKEN", kakaoAccessToken)

      Kakao.API.request({
        url: "/v2/user/me",
        success: (res) => {
          const kakaoId = res.id
          const kakao_account = res.kakao_account
          const nickname = kakao_account.profile.nickname
          // console.debug(kakaoId, nickname)
          loginUser("KAKAO", kakaoId, nickname, kakaoAccessToken)
        },
        fail: (error) => {
          console.error(error)
        },
      })
    },
    demoLogin() {
      loginUser("DEMO", "TEST_USER", "", "")
    },
  },
}
</script>

<style scoped lang="scss">
#wrapper {
  height: 80vh;
  position: relative;
  #kakao-login {
    position: absolute;
    top: 50%;
    left: 50%;
    /*요소의 높이/너비의 반(50%)만큼 위/왼쪽으로 이동*/
    transform: translate(-50%, -50%);
  }

  #demo-login {
    position: absolute;
    top: 60%;
    left: 50%;
    /*요소의 높이/너비의 반(50%)만큼 위/왼쪽으로 이동*/
    transform: translate(-50%, -50%);
  }
}
</style>
