<template>
  <td :class="classes">
    <m-table-cell-check-box v-if="column.type === 'CHECKBOX'" :column="column" :cell="cell"></m-table-cell-check-box>
    <m-number-cell v-else-if="column.type === 'NUMBER'" :column="column" :cell="cell"></m-number-cell>
    <m-table-cell-text-plain v-else-if="column.type === 'TEXT_PLAIN'" :column="column" :cell="cell"></m-table-cell-text-plain>
    <m-select-multi-cell v-else-if="column.type === 'SELECT_MULTI'" :column="column" :cell="cell"></m-select-multi-cell>
    <m-select-multi-cell v-else-if="column.type === 'SELECT_SINGLE'" :column="column" :cell="cell"></m-select-multi-cell>
    <m-list-task-cell v-else-if="column.type === 'LIST_TASK'" :column="column" :cell="cell"></m-list-task-cell>
    <m-list-simple-cell v-else-if="column.type === 'LIST_SIMPLE'" :column="column" :cell="cell"></m-list-simple-cell>
    <div v-else>{{ cell ? "⚠️" : "" }}</div>
  </td>
</template>

<script>
import MTableCellCheckBox from "@/components/common/MoonTypes/Cells/MTableCellCheckBox"
import MTableCellTextPlain from "@/components/common/MoonTypes/Cells/MTableCellTextPlain"
import MSelectMultiCell from "@/components/MoonTableColumnTypes/MSelectMulti/MSelectMultiCell"
import MNumberCell from "@/components/MoonTableColumnTypes/MNumber/MNumberCell"
import MListTaskCell from "@/components/MoonTableColumnTypes/MListTask/MListTaskCell"
import MListSimpleCell from "@/components/MoonTableColumnTypes/MListSimple/MListSimpleCell"
export default {
  name: "MTableCell",
  components: {
    MTableCellTextPlain,
    MNumberCell,
    MTableCellCheckBox,
    MSelectMultiCell,
    MListSimpleCell,
    MListTaskCell,
  },
  props: {
    column: Object,
    cell: Object,
  },
  computed: {
    classes() {
      return { right: this.column.type === "NUMBER" }
    },
  },
}
</script>

<style scoped lang="scss">
td {
  &.right {
    text-align: right;
  }
}
</style>
