<template>
  <div class="ui button" :class="classes" @click.stop="onClick">
    <i class="icon" v-if="icon !== null" :class="icon"></i>
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: "MBtn",
  props: {
    loading: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
    basic: Boolean,
    approve: { type: Boolean, default: false },
    deny: { type: Boolean, default: false },
    color: { type: String, default: null },
    icon: { type: String, default: null },
    fluid: Boolean,
    // onClick: { type: Function, required: true },
  },
  computed: {
    classes: function () {
      return [
        { loading: this.loading },
        { disabled: this.disabled },
        { primary: this.approve },
        { secondary: this.deny },
        { fluid: this.fluid },
        { basic: this.basic },
        // { icon: this.icon === null ? false : true },
        this.color ? this.color : "",
      ]
    },
  },
  methods: {
    onClick() {
      this.$emit("click")
    },
  },
}
</script>

<style scoped></style>
