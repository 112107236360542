<template>
  <fragment>
    <div class="ui labels">
      <div class="ui label moon-tag" :class="[item.color]" v-for="item in items" :key="item.id">
        {{ item.title }}
      </div>
    </div>
  </fragment>
</template>

<script>
export default {
  name: "MSelectMultiCell",
  props: {
    column: Object,
    cell: Object,
  },
  computed: {
    items() {
      return this.cell?.items ?? []
    },
  },
}
</script>

<style scoped>
.ui.labels > .label.moon-tag {
  margin-bottom: 1px;
}
.moon-tag {
  /*font-weight: normal;*/
  padding: 2px 4px;
}
</style>
