const state = () => ({
  formatNumber: true,
  firstDayOfWeek: 0, // 0: sun, 1: mon
  bgImgUrl: null,
})

// getters
const getters = {}

// actions
const actions = {}

// mutations
const mutations = {
  setSettings(state, settings) {
    state.formatNumber = settings.formatNumber
    state.firstDayOfWeek = settings.firstDayOfWeek
    state.bgImgUrl = settings.bgImgUrl
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
