<template>
  <input :value="value" @input="changeValue" @keypress.enter="enter" @blur="blur" :placeholder="placeholder" />
</template>

<script>
export default {
  name: "MTextInput",
  props: {
    value: {
      type: String,
    },
    placeholder: {
      type: String,
    },
  },
  methods: {
    changeValue(evt) {
      this.$emit("input", evt.target.value)
    },
    enter(evt) {
      this.$emit("enter", evt.target.value, evt)
      this.$emit("update", evt.target.value, evt)
    },
    blur(evt) {
      this.$emit("blur", evt.target.value, evt)
      this.$emit("update", evt.target.value, evt)
    },
  },
}
</script>

<style scoped></style>
