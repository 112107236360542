import { instanceWithAuth as axios } from "@/utils/axios"

const getConfig = {
  options: {
    progress: true,
  },
}

const loaderConfig = {
  options: {
    loader: true,
  },
}

const changeConfig = {
  options: {
    progress: true,
  },
}

const deleteConfig = {
  options: {
    progress: true,
    notice: true,
    successText: "삭제 성공",
    errorText: "삭제 실패",
  },
}

const postConfig = {
  options: {
    // progress: true,
    notice: true,
    loader: true,
    // toast: true,
    successText: "성공",
  },
}

const putConfig = {
  options: {
    // notice: true,
    progress: true,
    // successText: "성공",
  },
}
class TableService {
  getMyTables = () => {
    return axios.get("/api/v1/moon/tables", getConfig)
  }

  createTable(title, description) {
    return axios.post("/api/v1/moon/tables", { title, description }, postConfig)
  }

  getMyTable(tableId) {
    return axios.get(`/api/v1/moon/tables/${tableId}`, getConfig)
  }

  removeTable(tableId) {
    return axios.delete(`/api/v1/moon/tables/${tableId}`, deleteConfig)
  }

  changeTableOrders(orders) {
    return axios.post("/api/v1/moon/tables/orders", { orders }, changeConfig)
  }

  getMyTableWithSchema(tableId) {
    return axios.get(`/api/v1/moon/tables/${tableId}/schema`, getConfig)
  }

  addColumnGroup(tableId, columnGroupTitle) {
    return axios.post(
      `/api/v1/moon/tables/${tableId}/schema/column-groups`,
      {
        title: columnGroupTitle,
      },
      postConfig
    )
  }

  addColumn(tableId, columnGroupId, columnTitle, columnType) {
    return axios.post(
      `/api/v1/moon/tables/${tableId}/schema/column-groups/${columnGroupId}/columns`,
      {
        title: columnTitle,
        type: columnType,
      },
      postConfig
    )
  }

  removeColumnGroup(tableId, columnGroupId) {
    return axios.delete(`/api/v1/moon/tables/${tableId}/schema/column-groups/${columnGroupId}`, postConfig)
  }

  modifyColumn(tableId, columnGroupId, columnId, columnTitle, columnType) {
    return axios.put(
      `/api/v1/moon/tables/${tableId}/schema/column-groups/${columnGroupId}/columns/${columnId}`,
      {
        title: columnTitle,
        type: columnType,
      },
      putConfig
    )
  }

  removeColumn(tableId, columnGroupId, columnId) {
    return axios.delete(`/api/v1/moon/tables/${tableId}/schema/column-groups/${columnGroupId}/columns/${columnId}`, postConfig)
  }

  modifyColumnGroup(tableId, columnGroupId, columnGroupTitle) {
    return axios.put(
      `/api/v1/moon/tables/${tableId}/schema/column-groups/${columnGroupId}`,
      {
        title: columnGroupTitle,
      },
      putConfig
    )
  }

  getTableData(tableId, year, month) {
    return axios.get(`/api/v1/moon/tables/${tableId}/data?year=${year}&month=${month}`, getConfig)
  }

  getTableDataOneDay(tableId, year, month, day) {
    return axios.get(`/api/v1/moon/tables/${tableId}/data?year=${year}&month=${month}&day=${day}`, loaderConfig)
  }

  modifyTextValue(tableId, year, month, day, columnId, textValue) {
    return axios.put(
      `/api/v1/moon/tables/${tableId}/data`,
      {
        columnType: "TEXT_PLAIN",
        year,
        month,
        day,
        columnId,
        textValue,
      },
      changeConfig
    )
  }

  modifyNumberValue(tableId, year, month, day, columnId, numberValue) {
    return axios.put(
      `/api/v1/moon/tables/${tableId}/data`,
      {
        columnType: "NUMBER",
        year,
        month,
        day,
        columnId,
        numberValue,
      },
      changeConfig
    )
  }

  modifyChecked(tableId, year, month, day, columnId, checked) {
    return axios.put(
      `/api/v1/moon/tables/${tableId}/data`,
      {
        columnType: "CHECKBOX",
        year,
        month,
        day,
        columnId,
        checked,
      },
      changeConfig
    )
  }

  addItem(tableId, columnGroupId, columnId, text) {
    return axios
      .post(
        `/api/v1/moon/tables/${tableId}/schema/column-groups/${columnGroupId}/columns/${columnId}/items`,
        {
          title: text,
        },
        {
          options: {
            progress: true,
            toast: true,
            errorText: "아이템 추가 실패",
          },
        }
      )
      .then((response) => {
        console.log(response)
        return response.data
      })
  }

  addDataItem(tableId, year, month, day, columnId, itemId) {
    return axios.put(
      `/api/v1/moon/tables/${tableId}/data/items`,
      {
        action: "add",
        year,
        month,
        day,
        columnId,
        itemId,
      },
      {
        options: {
          progress: true,
          toast: true,
          errorText: "데이터 추가 실패",
        },
      }
    )
  }

  removeDataItem(tableId, year, month, day, columnId, itemId) {
    return axios.put(
      `/api/v1/moon/tables/${tableId}/data/items`,
      {
        action: "remove",
        year,
        month,
        day,
        columnId,
        itemId,
      },
      {
        options: {
          progress: true,
          toast: true,
          errorText: "데이터 삭제 실패",
        },
      }
    )
  }

  selectDataItem(tableId, year, month, day, columnId, itemId) {
    return axios.put(
      `/api/v1/moon/tables/${tableId}/data/items`,
      {
        action: "select",
        year,
        month,
        day,
        columnId,
        itemId,
      },
      {
        options: {
          progress: true,
          toast: true,
          errorText: "데이터 선택 실패",
        },
      }
    )
  }

  modifyDataItem(tableId, year, month, day, columnId, itemId, dataItem) {
    return axios.put(
      `/api/v1/moon/tables/${tableId}/data/items`,
      {
        action: "modify",
        year,
        month,
        day,
        columnId,
        itemId,
        dataItem,
      },
      {
        options: {
          progress: true,
          toast: true,
          errorText: "데이터 수정 실패",
        },
      }
    )
  }

  moveDataItem(tableId, year, month, day, columnId, itemId, targetDate) {
    return axios.put(
      `/api/v1/moon/tables/${tableId}/data/items`,
      {
        action: "move",
        year,
        month,
        day,
        columnId,
        itemId,
        targetDate,
      },
      {
        options: {
          progress: true,
          toast: true,
          errorText: "데이터 이동 실패",
        },
      }
    )
  }

  copyDataItem(tableId, year, month, day, columnId, itemId, targetDate) {
    return axios.put(
      `/api/v1/moon/tables/${tableId}/data/items`,
      {
        action: "copy",
        year,
        month,
        day,
        columnId,
        itemId,
        targetDate,
      },
      {
        options: {
          progress: true,
          toast: true,
          errorText: "데이터 복사 실패",
        },
      }
    )
  }

  removeItem(tableId, columnGroupId, columnId, itemId) {
    return axios.delete(`/api/v1/moon/tables/${tableId}/schema/column-groups/${columnGroupId}/columns/${columnId}/items/${itemId}`, {
      options: {
        progress: true,
        toast: true,
        errorText: "아이템 삭제 실패",
      },
    })
  }

  modifyItem(tableId, columnGroupId, columnId, item) {
    return axios.put(`/api/v1/moon/tables/${tableId}/schema/column-groups/${columnGroupId}/columns/${columnId}/items/${item.id}`, item, {
      options: {
        progress: true,
      },
    })
  }

  changeTableTitle(table) {
    return axios.put(`/api/v1/moon/tables/${table.id}`, table)
  }

  changeTableMemo(table) {
    return axios.put(`/api/v1/moon/tables/${table.id}`, table)
  }

  updateMeta(resourceType, resourceId, metaProperty, value) {
    return axios.post(
      `/api/v1/moon/tables/meta`,
      {
        resourceType: resourceType,
        resourceId: resourceId,
        propertyKey: metaProperty,
        propertyValue: value,
      },
      changeConfig
    )
  }

  getTableSummary(tableId) {
    return axios.get(`/api/v1/moon/tables/${tableId}/summary`).then((response) => {
      return response.data.data
    })
  }
}

export default new TableService()
