<template>
  <div>
    <div class="ui piled segment">
      <div class="ui form" @submit.prevent="createAccount">
        <div class="field">
          <label for="nickname">사용자 이름</label>
          <input type="text" id="nickname" v-model="nickname" />
        </div>
        <div class="ui right aligned container">
          <m-btn @click="createAccount" color="primary"> 저장 </m-btn>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MBtn from "@/components/common/MBtn"
import { $alert, $confirm } from "@/utils/SemanticUtil"
import * as AuthService from "@/service/AuthService"
export default {
  name: "CreateAccount",
  components: { MBtn },
  mixins: [PageMixin],
  props: {
    // kakaoUserId: { type: String, required: true },
    // kakaoNickname: { type: String, required: true },
  },
  data: () => {
    return {
      kakaoUserId: null,
      kakaoNickname: null,
      nickname: null,
    }
  },
  computed: {
    nicknameTrim() {
      return lodash.trim(this.nickname)
    },
  },
  methods: {
    createAccount() {
      const self = this

      if (!this.nicknameTrim) {
        $alert("사용자 이름을 입력해 주세요")
        return false
      }

      $confirm(
        "입력하신 정보로 정합니다.",
        "계정 생성 확인",

        () => {
          AuthService.createAccount(this.kakaoUserId, this.nicknameTrim).then(() => {
            self.$router.push({ name: "MyTables" })
          })
        }
      )
    },
  },
  created() {
    this.$store.commit("page/changeTitle", Page.CreateAccount.title)
  },
  mounted() {
    if (!this.$route.params.kakaoUserId) {
      this.$router.push({ name: "Login" })
    }

    this.kakaoUserId = this.$route.params.kakaoUserId
    this.kakaoNickname = this.$route.params.kakaoNickname
    this.nickname = this.kakaoNickname
  },
}
import lodash from "lodash"
import PageMixin from "@/mixins/PageMixin"
import { Page } from "@/define/Page"
</script>

<style scoped></style>
