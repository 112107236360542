import VueRouter from "vue-router"
import { checkAuth } from "@/service/AuthService"

import { Page } from "@/define/Page"

import ServerError from "@/pages/error/ServerError"
import NotFound from "@/pages/error/NotFound"

import Login from "@/pages/Login"
import CreateAccount from "@/pages/CreateAccount"

import MyTables from "@/pages/MyTables/MyTables"
import TableDataForm from "@/pages/TableDataForm"
import TableListView from "@/pages/TableListView"
import TableMonthlyView from "@/pages/TableMonthlyView"
import TableReportView from "@/pages/TableReportView"

const router = new VueRouter({
  mode: "history",
  routes: [
    { path: "/", redirect: { name: Page.MyTables.name } },
    { path: Page.Login.path, component: Login, name: Page.Login.name },
    { path: Page.CreateAccount.path, component: CreateAccount, name: Page.CreateAccount.name, props: true },
    // { path: "/auth/kakao", component: () => import("@/pages/auth/KakaoAuth"), name: "KakaoAuth" },

    { path: Page.MyTables.path, component: MyTables, name: Page.MyTables.name, meta: { requiresAuth: true } },
    { path: Page.NewTableForm.path, component: () => import("@/pages/NewTableForm"), name: Page.NewTableForm.name, meta: { requiresAuth: true } },
    { path: Page.TableEditor.path, component: () => import("@/pages/TableEditor"), name: Page.TableEditor.name, meta: { requiresAuth: true } },
    { path: Page.TableDataForm.path, component: TableDataForm, name: Page.TableDataForm.name, meta: { requiresAuth: true } },
    { path: Page.TableListView.path, component: TableListView, name: Page.TableListView.name, meta: { requiresAuth: true } },
    { path: Page.TableMonthlyView.path, component: TableMonthlyView, name: Page.TableMonthlyView.name, meta: { requiresAuth: true } },
    { path: Page.TableReportView.path, component: TableReportView, name: Page.TableReportView.name, meta: { requiresAuth: true } },

    { path: "/sample-page", component: () => import("@/pages/SamplePage"), name: "SamplePage" },

    { path: "/server-error", component: ServerError, name: "ServerError" },
    { path: "*", component: NotFound, name: "NotFound" },
  ],
})

router.beforeEach(async (to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    // 이 라우트는 인증이 필요하며 로그인 한 경우 확인하십시오.
    // 그렇지 않은 경우 로그인 페이지로 리디렉션하십시오.
    await checkAuth(next)
  } else {
    next() // 반드시 next()를 호출하십시오!
  }
})

export default router
