<template>
  <div>
    <p>
      처리 중 오류가 발생하였습니다.<br />
      다시 한 번 시도해 주세요.
    </p>
  </div>
</template>

<script>
export default {
  name: "ServerError",
  created() {
    this.$store.commit("page/changeTitle", "MOON TABLE")
  },
}
</script>

<style scoped></style>
