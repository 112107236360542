<template>
  <div class="item">
    <div class="right floated content">
      <i class="ui remove grey link icon" @click="removeTask"></i>
    </div>
    <div class="ui checkbox">
      <input type="checkbox" :checked="task.checked" :id="'task-' + task.id" @change="updateTask" />
      <label :for="'task-' + task.id">{{ task.title }}</label>
    </div>
  </div>
</template>

<script>
// import TableService from "@/service/TableService"
export default {
  name: "Task",
  props: {
    task: Object,
  },
  methods: {
    updateTask(evt) {
      const checked = evt.target.checked
      this.$emit("update", { ...this.task, checked: checked })
      // TableService.modifyDataItem(tableId, year, month, day, columnId, itemId, { ...this.task, checked: checked })
    },
    removeTask() {
      this.$emit("remove", this.task)
    },
  },
}
</script>

<style scoped></style>
