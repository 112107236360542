<template>
  <div class="field" :class="{ error: !valid }">
    <div class="ui checkbox" :class="classes">
      <input :id="id" type="checkbox" :checked="value" @change="update" />
      <label :for="id">{{ label }}</label>
    </div>
  </div>
</template>

<script>
import DataInputMixin from "@/mixins/DataInputMixin"

export default {
  name: "MFieldCheckBox",
  mixins: [DataInputMixin],
  props: {
    id: [String, Number],
    value: Boolean,
    label: String,
    valid: Boolean,
    toggle: Boolean,
  },
  computed: {
    classes() {
      return [this.toggle ? "toggle" : ""]
    },
  },
  methods: {
    update(evt) {
      this.$emit("input", evt.target.checked)
      this.$emit("update", evt.target.checked)
    },
  },
}
</script>

<style scoped></style>
