<template>
  <div>{{ cellText }}</div>
</template>

<script>
export default {
  name: "MListSimpleCell",
  props: {
    column: Object,
    cell: Object,
  },
  computed: {
    items() {
      return this.cell?.items ?? []
    },
    cellText() {
      if (this.items.length === 0) {
        return ""
      }
      return `${this.items.length} Items`
    },
  },
}
</script>

<style scoped></style>
