export const types = [
  { value: "TEXT_PLAIN", name: "텍스트", icon: "pen nib", active: true },
  { value: "NUMBER", name: "숫자", icon: "chart bar", active: true },
  { value: "CHECKBOX", name: "체크박스", icon: "check", active: true },
  { value: "SELECT_SINGLE", name: "단일 선택", icon: "caret square down", active: true },
  { value: "SELECT_MULTI", name: "다중 선택", icon: "tags", active: true },
  { value: "LIST_SIMPLE", name: "단순 목록", icon: "list", active: true },
  { value: "LIST_TASK", name: "작업 목록", icon: "tasks", active: true },
  { value: "LIST_NUMBER", name: "숫자 목록", icon: "hashtag", active: true }, // calculator
  { value: "LIST_TIME", name: "시간 목록", icon: "clock outline", active: true },
  { value: "FILE", name: "파일", icon: "file alternate", active: false },
  { value: "LINK", name: "링크", icon: "paperclip", active: false },
  { value: "TEXT_MD", name: "마크다운", icon: "markdown", active: false },
]
import lodash from "lodash"
export function findType(typeName) {
  return lodash.find(types, { value: typeName })
}
