<template>
  <fragment>
    <m-field-text v-if="column.type === 'TEXT_PLAIN'" :id="column.id" :valid="valid" v-model="v.textValue" :label="column.title" @update="updateTextValue" />
    <m-number v-else-if="column.type === 'NUMBER'" :id="column.id" :valid="valid" v-model="v.numberValue" :label="column.title" @update="updateNumberValue" />
    <m-field-check-box
      v-else-if="column.type === 'CHECKBOX'"
      :id="column.id"
      :valid="valid"
      v-model="v.checked"
      :label="column.title"
      @update="updateCheckBox"
    ></m-field-check-box>
    <m-select-multi
      v-else-if="column.type === 'SELECT_MULTI'"
      :column="column"
      :valid="valid"
      v-model="v.items"
      :label="column.title"
      @select="addDataItem"
      @remove="removeDataItem"
    ></m-select-multi>
    <m-select-multi
      v-else-if="column.type === 'SELECT_SINGLE'"
      single
      :column="column"
      :valid="valid"
      v-model="v.items"
      :label="column.title"
      @select="selectDataItem"
      @remove="removeDataItem"
    ></m-select-multi>
    <m-list-simple-form
      v-else-if="column.type === 'LIST_SIMPLE'"
      :column="column"
      :valid="valid"
      v-model="v.items"
      :label="column.title"
      @addItem="addDataItem"
      @update="modifyDataItem"
      @remove="removeDataItem"
      @move="moveDataItem"
      @copy="copyDataItem"
    ></m-list-simple-form>
    <m-list-task-form
      v-else-if="column.type === 'LIST_TASK'"
      :column="column"
      :valid="valid"
      v-model="v.items"
      :label="column.title"
      @addTask="addDataItem"
      @update="modifyDataItem"
      @remove="removeDataItem"
    ></m-list-task-form>
    <div v-else>⚠️ {{ column.type }}</div>
  </fragment>
</template>

<script>
import MNumber from "@/components/MoonTableColumnTypes/MNumber/MNumber"
import MFieldText from "@/components/common/MFieldText"
import MFieldCheckBox from "@/components/common/MoonTypes/MFieldCheckBox"
import TableService from "@/service/TableService"
import MSelectMulti from "@/components/MoonTableColumnTypes/MSelectMulti/MSelectMulti"
import MListTaskForm from "@/components/MoonTableColumnTypes/MListTask/MListTaskForm"
import MListSimpleForm from "@/components/MoonTableColumnTypes/MListSimple/MListSimpleForm"
export default {
  name: "TableData",
  components: {
    MListSimpleForm,
    MListTaskForm,
    MSelectMulti,
    MFieldText,
    MNumber,
    MFieldCheckBox,
    // MFieldDropdown
  },
  props: {
    year: { type: Number, required: true },
    month: { type: Number, required: true },
    day: { type: Number, required: true },
    column: {
      type: Object,
      required: true,
    },
    row: {
      type: Object,
      required: true,
      default: () => ({}),
    },
  },
  data() {
    return {
      valid: true,
      v: {
        textValue: "",
        numberValue: null,
        checked: false,
        items: [],
      },
    }
  },
  computed: {},
  watch: {
    row() {
      this.v = this.row[this.column.id] ?? {
        textValue: "",
        numberValue: null,
        checked: false,
        items: [],
      }
    },
  },
  methods: {
    changeValue(value) {
      this.value.numberValue = value
    },
    updateTextValue(value) {
      const { tableId, id: columnId } = this.column
      TableService.modifyTextValue(tableId, this.year, this.month, this.day, columnId, value)
    },
    updateNumberValue(value) {
      const { tableId, id: columnId } = this.column
      TableService.modifyNumberValue(tableId, this.year, this.month, this.day, columnId, value)
      this.$emit("update", this.column.id, { numberValue: Number(value) })
    },

    updateCheckBox(value) {
      const { tableId, id: columnId } = this.column
      TableService.modifyChecked(tableId, this.year, this.month, this.day, columnId, value)
    },
    addDataItem(item) {
      const { tableId, id: columnId } = this.column
      TableService.addDataItem(tableId, this.year, this.month, this.day, columnId, item.id)
    },
    removeDataItem(item) {
      const { tableId, id: columnId } = this.column
      TableService.removeDataItem(tableId, this.year, this.month, this.day, columnId, item.id)
    },
    selectDataItem(item) {
      const { tableId, id: columnId } = this.column
      TableService.selectDataItem(tableId, this.year, this.month, this.day, columnId, item.id)
    },
    modifyDataItem(item) {
      const { tableId, id: columnId } = this.column
      TableService.modifyDataItem(tableId, this.year, this.month, this.day, columnId, item.id, item)
    },
    moveDataItem(item, targetDate) {
      console.log("MOVE", item, targetDate)
      const { tableId, id: columnId } = this.column
      TableService.moveDataItem(tableId, this.year, this.month, this.day, columnId, item.id, targetDate)
    },
    copyDataItem(item, targetDate) {
      console.log("COPY", item, targetDate)
      const { tableId, id: columnId } = this.column
      TableService.copyDataItem(tableId, this.year, this.month, this.day, columnId, item.id, targetDate)
    },
  },
}
</script>

<style scoped></style>
