<template>
  <div id="fab" class="ui black circular icon big button" @click="onClink" :data-content="popupText" ref="fab">
    <i class="icon" v-bind:class="[icon]"></i>
  </div>
</template>

<script>
export default {
  name: "MFab",
  props: {
    icon: { type: String, default: "plus" },
    showPopup: Boolean,
    popupText: { type: String, required: false },
  },
  watch: {
    showPopup(showPopup) {
      if (showPopup) {
        $(this.$refs.fab).popup("show")
      }
    },
  },
  methods: {
    onClink() {
      this.$emit("click")
    },
  },
  mounted() {
    $(this.$refs.fab).popup({
      position: "top center",
    })
  },
}
</script>

<style scoped>
#fab {
  z-index: 200;
  position: fixed;
  bottom: 0px;
  left: 50%;
  transform: translate(-50%, -50%);

  /*-webkit-box-shadow: 0px 0px 5px 1px rgba(0, 0, 0, 0.4);*/
  /*box-shadow: 0px 0px 5px 1px rgba(0, 0, 0, 0.4);*/

  box-shadow: #edab0c 5px 5px 0px;
}
</style>
