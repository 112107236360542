export const Page = {
  CreateAccount: { name: "CreateAccount", title: "계정 생성", description: "계정 생성", path: "/create-account" },
  Login: { name: "Login", title: "MOON TABLE", description: "로그인", path: "/login" },
  MyTables: { name: "MyTables", title: "MOON TABLE", description: "테이블 리스트", path: "/tables" },
  NewTableForm: { name: "NewTableForm", title: "테이블 추가", description: "테이블 추가", path: "/tables/create" },
  TableEditor: { name: "TableEditor", title: "테이블 설정", description: "테이블 설정", path: "/tables/:id/editor" },
  TableDataForm: { name: "TableDataForm", title: "데이터 입력", description: "데이터 입력", path: "/tables/:id/form" },
  TableListView: { name: "TableListView", title: "리스트로 보기", description: "리스트로 보기", path: "/tables/:id/list" },
  TableMonthlyView: { name: "TableMonthlyView", title: "달력으로 보기", description: "달력으로 보기", path: "/tables/:id/monthly" },
  TableReportView: { name: "TableReportView", title: "리포트 보기", description: "리포트 보기", path: "/tables/:id/report" },
}
