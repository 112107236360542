import { instance as axios } from "@/utils/axios"
import store from "@/store"
import router from "@/router"

const loginConfig = {
  options: {
    // progress: true,
    notice: true,
    loader: true,
    // toast: true,
    successText: "로그인 성공",
  },
}

export function createAccount(kakaoUserId, nickname) {
  return axios.post(
    "/api/v1/moon/users",
    {
      socialType: "KAKAO",
      socialUserId: kakaoUserId,
      nickname: nickname,
    },
    {
      options: {
        loader: true,
        popup: true,
        successText: "새로운 계정을 생성하였습니다",
        errorText: "계정 생성을 실패하였습니다",
      },
    }
  )
}

export function kakaoLogout() {
  Kakao.Auth.logout((response) => {
    console.debug("LOGOUT", response)
  })
}

export function checkAuth(next) {
  const token = store.state.token
  const user = store.state.user
  if (token && user.loggedIn) {
    next()
  } else {
    store.commit("setToken", null)
    store.commit("user/logout")

    next({ name: "Login" })
  }
}

export function loginUser(socialType, socialUserId, socialUserNickname, socialAccessToken) {
  // 계정 있는지 확인
  fetchMoonUser(socialType, socialUserId)
    .then(async (moonUserRes) => {
      if (moonUserRes.data.success) {
        const user = {}
        user.id = moonUserRes.data.data.id
        user.nickname = moonUserRes.data.data.nickname
        store.commit("user/login", user)

        const token = await fetchMoonToken(socialType, socialUserId, socialAccessToken)
        store.commit("setToken", token)
        // console.log("token", token)
        router.push({ name: "MyTables" })
      }
    })
    .catch((error) => {
      if (error.response && error.response.status === 404) {
        router.push({ name: "CreateAccount", params: { kakaoUserId: socialUserId, kakaoNickname: socialUserNickname } })
      } else {
        router.push({ name: "ServerError" })
      }
    })
}

export function logoutUser() {
  kakaoLogout()
  store.commit("setToken", null)
  store.commit("user/logout")
  router.push({ name: "Login" })
}

async function fetchMoonToken(socialType, socialUserId, socialAccessToken) {
  // 인가 코드와 액세스 토큰 교환
  const params = new URLSearchParams()
  params.append("socialType", socialType)
  params.append("socialUserId", socialUserId)
  params.append("socialAccessToken", socialAccessToken)

  return await axios.post("/api/v1/moon/auth/token", params, loginConfig).then(({ data }) => {
    return data.token
  })
}

export function fetchMoonUser(socialType, socialId) {
  return axios.get("/api/v1/moon/users/" + socialType + "/" + socialId)
}

/*
export async function checkUser(next) {
  const accessToken = getKakaoAccessTokenFromStorage()
  console.debug("1. ACCESS TOKEN", accessToken)
  if (accessToken) {
    // 저장된 액세스 토큰이 있다면
    // state 에서 사용자 정보 확인
    console.debug("1.1. STORED USER: ", store.state.user)

    const loggedIn = store.state.user.loggedIn

    if (loggedIn) {
      // 사용자 정보가 저장되어 있다면 목적 페이지로 이동
      console.debug("1.1.1. LOGGED IN => NEXT()", loggedIn)
      next()
      return
    } else {
      // 사용자 정보가 없다면 사용자정보 수집하여 저장 후 목적페이지로 이동
      // 1. 카카오 사용자 정보

      console.debug("1.2. 카카오 사용자 정보 가져오기")

      const user = {}

      try {
        const kakaoUser = await fetchKakaoUser()
        console.debug("1.2.1. 카카오 사용자 정보 가져오기:", kakaoUser)
        user.kakaoUserId = kakaoUser.id
        user.kakaoNickname = kakaoUser.kakao_account.profile.nickname
        console.debug("1.2.1. 카카오 사용자 정보 가져오기:", user)
      } catch (error) {
        // 토큰이 만료되었다면 갱신, 아니면 로그인 페이지로 이동
        console.error("1.2. 카카오 사용자 정보 가져오기 에러")
        console.error(error)
        if (error.code === -401) {
          console.debug("1.2.1.1. 토큰 만료")
          refreshKakaoToken()
            .then(async () => {
              console.debug("1.2.1.1. 토큰 갱신 성공")
              const kakaoUser = await fetchKakaoUser()
              console.debug("1.2.1. 다시 카카오 사용자 정보 가져오기:", kakaoUser)
              user.kakaoUserId = kakaoUser.id
              user.kakaoNickname = kakaoUser.kakao_account.profile.nickname
              console.debug("1.2.1. 다시 카카오 사용자 정보 가져오기:", user)
            })
            .catch(() => {
              console.debug("1.2.1.2. 토큰 갱신 실패")
              next({ name: "Login" })
              return
            })
        } else {
          console.debug("1.2.1.2. 그외 에러")
          next({ name: "Login" })
          return
        }
      }
      // 2. MOON USER 정보
      try {
        console.debug("2. MOON USER 정보 가져오기 시작 -> 없으면 가입으로", user)
        const moonUserRes = await fetchMoonUser("KAKAO", user.kakaoUserId)
        console.debug("2. MOON USER 정보 가져오기", moonUserRes)
        // STATE 에 사용자 정보 저장 MOON ID, NICKNAME
        if (moonUserRes.data.success) {
          user.id = moonUserRes.data.data.id
          user.nickname = moonUserRes.data.data.nickname

          console.debug("2.1 MOON USER 정보 가져오기 성공 -> 저장", user)
          store.commit("user/login", user)
          next()
          return
        } else {
          console.debug("2.2 MOON USER 정보 가져오기 실패")
          suToastError(moonUserRes.data.errorMessage)
          next({ name: "ServerError" })
          return
        }
      } catch (error) {
        console.log("MOON USER ERROR:", error)
        if (error.response) {
          if (error.response.status === 404) {
            // MOON USER 가 없으면 계정 생성 페이지로 이동
            next({ name: "CreateAccount", params: { kakaoUserId: user.kakaoUserId, kakaoNickname: user.kakaoNickname } })
            return
          }
        }
        next({ name: "ServerError" })
        return
      }
      // 3. 사용자 정보 저장

      // 4. 목적 페이지로 이동
    }
  } else {
    // 저장된 엑세스 토큰이 없다면
    // 로그인 화면으로 이동
    next({ name: "Login" })
    return
  }
}
*/

/*
function fetchKakaoUser() {
  return new Promise((resolve, reject) => {
    // 카카오 고유 ID, 닉네임 가져오기
    Kakao.Auth.setAccessToken(getKakaoAccessTokenFromStorage())
    Kakao.API.request({
      url: "/v2/user/me",
      success: function (response) {
        resolve(response)
      },
      fail: function (error) {
        reject(error)
      },
    })
  })
}
*/

/*
export function getKakaoAccessTokenFromStorage() {
  return localStorage.getItem(KAKAO_ACCESS_TOKEN)
}
*/

/*
export function getKakaoRefreshTokenFromStorage() {
  return localStorage.getItem(KAKAO_REFRESH_TOKEN)
}
*/

/*
export function saveKakaoTokenToStorage(accessToken, refreshToken) {
  localStorage.setItem(KAKAO_ACCESS_TOKEN, accessToken)
  if (refreshToken) {
    localStorage.setItem(KAKAO_REFRESH_TOKEN, refreshToken)
  }
}
*/

/*
export function clearKakaoTokenToStorage() {
  localStorage.removeItem(KAKAO_ACCESS_TOKEN)
  localStorage.removeItem(KAKAO_REFRESH_TOKEN)
}
*/

/*
export function fetchKakaoToken(code) {
  // 인가 코드와 액세스 토큰 교환
  const params = new URLSearchParams()
  params.append("grant_type", "authorization_code")
  params.append("client_id", restApiKey)
  params.append("redirect_uri", redirectUrl)
  params.append("code", code)

  return axios.post("https://kauth.kakao.com/oauth/token", params).then(({ data }) => {
    return data
  })
}
*/

/*
export function refreshKakaoToken() {
  const params = new URLSearchParams()
  params.append("grant_type", "refresh_token")
  params.append("client_id", restApiKey)
  params.append("refresh_token", getKakaoRefreshTokenFromStorage())
  return axios.post("https://kauth.kakao.com/oauth/token", params).then(({ status, data }) => {
    if (status === 200) {
      saveKakaoTokenToStorage(data["access_token"], data["refresh_token"])
    } else {
      throw new Error("tail refresh token: " + status)
    }
  })
}
*/
