<template>
  <div class="field" :class="{ error: !valid }">
    <label>{{ label }}</label>
    <div class="moon-multiselect">
      <multiselect
        v-model="selectedValues"
        :options="options"
        label="title"
        track-by="id"
        :multiple="!single"
        taggable
        tag-placeholder="새로운 아이템 추가"
        placeholder="검색 또는 추가"
        selectedLabel="선택됨"
        @tag="addItem"
        @select="handleSelect"
        @remove="handleRemove"
      >
        <template slot="tag" slot-scope="props">
          <div class="ui mini label moon-tag" :class="[props.option.color]">
            {{ props.option.title }}
            <i class="delete icon" @click="props.remove(props.option)"></i>
          </div>
        </template>
      </multiselect>
    </div>
  </div>
</template>

<script>
import TableService from "@/service/TableService"
export default {
  name: "MSelectMulti",
  props: {
    column: {
      type: Object,
      required: true,
    },
    value: Array,
    label: String,
    valid: Boolean,
    single: Boolean,
  },
  data() {
    return {
      selectedValues: [],
      options: [],
    }
  },
  computed: {},
  watch: {
    value() {
      this.selectedValues = this.value
    },
    column() {
      this.options = this.column.items ?? []
    },
  },
  created() {
    this.options = this.column.items ?? []
    this.selectedValues = this.value
  },
  methods: {
    async addItem(newItemText) {
      const { id: columnId, columnGroupId, tableId } = this.column
      const item = await TableService.addItem(tableId, columnGroupId, columnId, newItemText)
      this.options.push(item)
      this.selectedValues.push(item)
      this.handleSelect(item)
    },
    handleSelect(item) {
      this.$emit("select", item)
    },
    handleRemove(item) {
      this.$emit("remove", item)
    },
  },
}
</script>
<style scoped>
.moon-tag {
  font-weight: normal;
}
</style>
