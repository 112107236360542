<template>
  <div>{{ cellText }}</div>
</template>

<script>
import lodash from "lodash"
export default {
  name: "MListTaskCell",
  props: {
    column: Object,
    cell: Object,
  },
  computed: {
    items() {
      return this.cell?.items ?? []
    },
    doneItems() {
      return lodash.chain(this.items).filter({ checked: true }).value()
    },
    done() {
      return this.doneItems.length
    },
    tasks() {
      return this.items.length
    },
    cellText() {
      if (this.tasks === 0) {
        return ""
      }
      return `${this.done}/${this.tasks} Tasks`
    },
  },
}
</script>

<style scoped></style>
