<template>
  <div id="loader" class="ui yellow indeterminate tiny progress" v-show="show">
    <div class="bar"></div>
  </div>
</template>

<script>
export default {
  name: "MProgress",
  computed: {
    show() {
      return this.$store.state.page.progress
    },
  },
}
</script>

<style scoped>
#loader {
  width: 100%;
  position: fixed;
  top: 0px;
  margin: 0;
  z-index: 1000;
}

#loader .bar {
  height: 3px;
}
</style>
