<template>
  <div class="item">
    <div class="right floated content">
      <div class="ui floating mini dropdown" ref="menu">
        <i class="ellipsis horizontal grey link icon"></i>
        <div class="menu">
          <div class="item" @click="openCopyItemDialog">다른 날로...</div>
          <div class="item" @click="openModifyItemDialog">수정</div>
          <div class="item" @click="openRemoveItemDialog">삭제</div>
        </div>
      </div>
    </div>
    <i class="circle small icon"></i>
    <div class="content">
      <span>{{ item.title }}</span>
    </div>
    <m-dialog v-model="modifyItemDialog" header-text="항목 수정">
      <template #default>
        <div class="ui form">
          <m-field-text v-model="modifyItemTitle" :valid="modifyItemValid"></m-field-text>
        </div>
      </template>
      <template #actions>
        <m-btn deny @click="modifyItemDialog = false">취소</m-btn>
        <m-btn approve icon="check" @click="modifyItem">확인</m-btn>
      </template>
      <div></div>
    </m-dialog>
    <m-dialog v-model="copyItemDialog">
      <template #actions>
        <m-btn deny @click="copyItemDialog = false">취소</m-btn>
        <m-btn approve icon="check" @click="copyItem">확인</m-btn>
      </template>
      <div>
        <div class="ui form">
          <m-field-dropdown label="다른 날로" valid v-model="copyItemMethod" :items="copyItemMethods"></m-field-dropdown>
          <div class="field" :class="{ error: this.copyItemDateError }">
            <label>날짜 선택</label>
            <div class="ui calendar" ref="copyItemCalendar">
              <div class="ui input left icon">
                <i class="calendar icon"></i>
                <input type="text" v-model="copyItemDate" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </m-dialog>
  </div>
</template>

<script>
import { $confirm } from "@/utils/SemanticUtil"
import MDialog from "@/components/common/MDialog"
import MBtn from "@/components/common/MBtn"
import MFieldDropdown from "@/components/common/MFieldDropdown"
import moment from "moment"
import MFieldText from "@/components/common/MFieldText"
import TableService from "@/service/TableService"

export default {
  name: "Item",
  components: { MFieldText, MFieldDropdown, MBtn, MDialog },
  props: {
    item: Object,
  },
  data() {
    return {
      modifyItemDialog: false,
      modifyItemValid: true,
      modifyItemTitle: null,
      copyItemDialog: false,
      copyItemMethod: "move",
      copyItemMethods: [
        { name: "이동", value: "move", active: true },
        { name: "복사", value: "copy", active: true },
      ],
      copyItemDate: moment({
        year: Number(this.$store.state.page.date.year),
        month: Number(this.$store.state.page.date.month) - 1,
        day: Number(this.$store.state.page.date.day),
      }).format("YYYY-MM-DD"),
      copyItemDateError: false,
    }
  },
  methods: {
    openCopyItemDialog() {
      this.copyItemDialog = true
    },
    openModifyItemDialog() {
      this.modifyItemTitle = this.item.title
      this.modifyItemValid = true
      this.modifyItemDialog = true
    },
    openRemoveItemDialog() {
      $confirm(`<b>${this.item.title}</b> 아이템을 삭제하시겠습니까?`, "삭제 확인", () => {
        this.removeItem()
      })
    },
    copyItem() {
      this.copyItemDateError = false

      const currentDate = moment({
        year: Number(this.$store.state.page.date.year),
        month: Number(this.$store.state.page.date.month) - 1,
        day: Number(this.$store.state.page.date.day),
      }).format("YYYY-MM-DD")

      if (currentDate === this.copyItemDate) {
        this.copyItemDateError = true
        return
      }

      this.copyItemDialog = false

      this.$nextTick(() => {
        this.$emit(this.copyItemMethod, this.item, this.copyItemDate)
      })
    },
    async modifyItem() {
      if (!this.modifyItemTitle) {
        this.modifyItemValid = false
        return false
      }

      const { tableId, columnGroupId, columnId } = this.item
      const item = { ...this.item, title: this.modifyItemTitle }
      await TableService.modifyItem(tableId, columnGroupId, columnId, item)
      this.$emit("update", item)
      this.modifyItemDialog = false
    },
    removeItem() {
      this.$emit("remove", this.item)
    },
  },
  mounted() {
    const self = this
    const menu = this.$refs.menu
    $(menu).dropdown({
      action: "nothing",
    })
    const cal = this.$refs.copyItemCalendar
    $(cal).calendar({
      inline: true,
      type: "date",
      onSelect(date, mode) {
        console.log(date, mode)
        self.copyItemDate = moment(date).format("YYYY-MM-DD")
      },
      formatter: {
        date: function (date) {
          if (!date) return ""
          const day = date.getDate()
          const monthIndex = date.getMonth()
          const year = date.getFullYear()
          return moment({ year: year, month: monthIndex, day: day }).format("YYYY-MM-DD")
        },
      },
    })
  },
}
</script>

<style scoped></style>
