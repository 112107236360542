// import "../public/assets/semantic-ui/semantic.css"
import Vue from "vue"
import VueRouter from "vue-router"
import App from "./App.vue"
import router from "@/router"
import store from "@/store"
import Fragment from "vue-fragment"
import Multiselect from "vue-multiselect"
// import "vue-multiselect/dist/vue-multiselect.min.css"
import "@/styles/moon-multiselect.scss"
import VCalendar from "v-calendar"

Vue.use(VCalendar)

Vue.use(Fragment.Plugin)

// register globally
Vue.component("multiselect", Multiselect)

Vue.config.productionTip = false

Vue.use(VueRouter)

// KAKAO API
Kakao.init(process.env.VUE_APP_KAKAO_JS_KEY)
Kakao.isInitialized()

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app")
