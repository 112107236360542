<template>
  <tr :class="{ today: isToday, sunday: getWeekdayIndex === 0 }">
    <td class="right aligned col-day">
      <router-link :to="{ name: 'TableDataForm', params: { id: tableId }, query: { date: dt } }">
        {{ getDay }}<span v-if="showWeekday">({{ getWeekday }})</span>
      </router-link>
    </td>
    <m-table-cell v-for="column in columns" :key="column.id" :column="column" :cell="pickData(column.id)"></m-table-cell>
  </tr>
</template>

<script>
import moment from "moment"
import { Weekdays } from "@/define/Weekday"
import MTableCell from "@/pages/TableListView/MTableCell"
export default {
  name: "TableDataRow",
  components: { MTableCell },
  props: {
    tableId: [Number, String],
    dt: String,
    row: Object,
    columns: Array,
  },
  data() {
    return {
      showWeekday: false,
    }
  },
  computed: {
    isToday() {
      const today = moment().format("YYYY-MM-DD")
      return today === this.dt
    },
    getDay() {
      return this.dt.substr(8, 2) * 1 + "일"
    },
    getWeekday() {
      return Weekdays[moment(this.dt).weekday()]
    },
    getWeekdayIndex() {
      return moment(this.dt).weekday()
    },
  },
  methods: {
    pickData(columnId) {
      return this.row[columnId]
    },
  },
}
</script>

<style scoped></style>
