<template>
  <header id="moon-header" class="ui top fixed borderless mini menu">
    <div class="ui container">
      <router-link to="/">
        <div id="header-icon" class="item">
          <img :src="logo" claas="" />
        </div>
      </router-link>
      <div class="item">
        <div class="ui header">{{ title }}</div>
      </div>
      <div class="right menu">
        <!--        <router-link :to="{ name: 'SamplePage' }" class="link item"> Components </router-link>-->

        <div v-if="isLoggedIn" id="header-user-info" class="ui dropdown item">
          <i class="user icon"></i>
          {{ nickname }}
          <i class="dropdown icon"></i>
          <div class="menu">
            <!--            <div class="item">Setting</div>-->
            <div class="item" @click.stop="logout">Logout</div>
          </div>
        </div>
        <div v-if="!isLoggedIn" class="ui item">
          <router-link :to="{ name: 'Login' }" class="ui primary button">
            <i class="user icon"></i>
            로그인</router-link
          >
        </div>
      </div>
    </div>
  </header>
  <!--    <m-loader></m-loader>-->
</template>

<script>
import logo from "/src/assets/logo_t.png"
import { logoutUser } from "@/service/AuthService"
// import MLoader from "@/components/common/MLoader"

export default {
  name: "MoonHeader",
  components: {
    // MLoader,
  },
  data: () => {
    return {
      logo: logo,
    }
  },
  computed: {
    title() {
      return this.$store.state.page.title
    },
    subTitle() {
      return "Login"
    },
    isLoggedIn() {
      return this.$store.state.user.loggedIn
    },
    nickname() {
      return this.$store.state.user.nickname
    },
  },
  watch: {
    isLoggedIn(value) {
      // console.log("isLoggedIn", value)
      if (value) {
        this.$nextTick(() => {
          $(".ui.dropdown").dropdown()
        })
      }
    },
  },
  mounted() {
    if (this.isLoggedIn) {
      this.$nextTick(() => {
        $(".ui.dropdown").dropdown()
      })
    }
  },
  methods: {
    logout() {
      logoutUser()
    },
  },
}
</script>

<style scoped lang="scss">
#moon-header {
  //background-color: $moon-bg-color;
  //.ui.container {
  //  border-bottom: 1px solid $moon-bd-color;
  //}
}
.sub-title {
  color: #ccc;
}
</style>
