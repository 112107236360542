<template>
  <div class="field" :class="{ error: !valid }">
    <label>{{ label }}</label>
    <input type="text" :value="textValue" @keyup="changeInput" @keypress.enter="update" @blur="update" />
  </div>
</template>

<script>
import DataInputMixin from "@/mixins/DataInputMixin"

export default {
  name: "MFieldText",
  components: {},
  mixins: [DataInputMixin],
  props: {
    id: [String, Number],
    value: String,
    label: String,
    valid: Boolean,
  },
  computed: {
    textValue() {
      return this.value
    },
  },
  methods: {
    changeInput(evt) {
      this.$emit("input", evt.target.value)
    },
  },
}
</script>

<style scoped></style>
