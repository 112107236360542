<template>
  <div>
    <page-menu :links="links" :date="formatDate">
      <m-date-button type="month" :year="year" :month="month" :day="day" @prev="movePrevMonth" @next="moveNextMonth" @select="selectMonth"></m-date-button>
    </page-menu>
    <div id="calendar">
      <v-calendar ref="cal" is-expanded trim-weeks transition="slide-h" :first-day-of-week="firstDayOfWeek" :attributes="attributes" @dayclick="handleDayClick">
        <!--        <template #header><div></div> </template>-->
        <!--        <template #header-left-button><div></div> </template>-->
        <!--        <template #header-right-button><div></div> </template>-->
      </v-calendar>
    </div>
  </div>
</template>

<script>
import TableService from "@/service/TableService"
import TableMixin from "@/mixins/TableMixin"
import PageMenu from "@/components/common/PageMenu"
import lodash from "lodash"
import moment from "moment"
import MDateButton from "@/components/common/MDateButton"
export default {
  name: "TableMonthlyView",
  components: { MDateButton, PageMenu },
  mixins: [TableMixin],
  data() {
    return {
      links: [
        { title: "테이블 보기", to: { name: "TableListView", params: { id: this.$route.params.id } }, icon: "table" },
        { title: "리포트 보기", to: { name: "TableReportView", params: { id: this.$route.params.id } }, icon: "area chart" },
        { title: "테이블 설정", to: { name: "TableEditor", params: { id: this.$route.params.id } }, icon: "cog" },
        { title: "테이블 리스트", to: { name: "MyTables" }, icon: "th list" },
      ],
      attributes: [],
    }
  },
  computed: {
    firstDayOfWeek() {
      return this.$store.state.settings.firstDayOfWeek + 1
    },
  },
  async created() {
    const tableId = this.$route.params.id

    const res = await TableService.getMyTableWithSchema(tableId)

    const {
      data: {
        data: { title, columnGroups },
      },
    } = res
    this.$store.commit("page/changeTitle", title)
    this.columnGroups = columnGroups
    this.setDate()
    await this.fetchData()
  },
  methods: {
    handleDayClick(day) {
      console.log(day)
      const tableId = this.$route.params.id
      this.$router.push({ name: "TableDataForm", params: { id: tableId }, query: { date: day.id } })
    },
    fetchData: async function () {
      const tableId = this.$route.params.id
      TableService.getTableData(tableId, this.year, this.month).then(({ data: { data: dtDataMap } }) => {
        // console.log(dtDataMap)

        const attributes = lodash
          .chain(dtDataMap)
          .mapValues((value, key) => {
            return { dates: key, col: lodash.values(value) }
          })
          .values()
          .filter((o) => o.col.length > 0)
          .flatMap((o) => {
            return o.col.map((c) => {
              c.dot = true
              // c.highlight = {
              //   color: "orange",
              //   fillMode: "light",
              // }
              c.key = c.id
              c.dates = o.dates
              return c
            })
          })
          .filter((c) => {
            switch (c.type) {
              case "TEXT_PLAIN":
                return c.textValue
              case "NUMBER":
                return c.numberValue
              case "CHECKBOX":
                return c.checked
              case "SELECT_SINGLE":
              case "SELECT_MULTI":
                return c.items?.length
              default:
                return false
            }
          })
          // .map((c) => {
          //   c.highlight = {
          //     color: "orange",
          //     fillMode: "light",
          //   }
          //   c.key = c.dates
          //   return c
          // })
          .value()
        // lodash.values(
        //   lodash.mapValues(dtDataMap, (value, key) => {
        //     return { dates: key, col: value }
        //   })
        // )
        // console.log(attributes)
        attributes.push({
          key: "today",
          dates: moment().format("YYYY-MM-DD"),
          highlight: { color: "blue", fillMode: "light" },
        })
        this.attributes = attributes
      })

      const cal = this.$refs.cal
      await cal.move(this.formatDate)
    },
  },
}
</script>

<style lang="scss">
#calendar {
  .vc-container {
    border: 0;
  }

  .vc-header,
  .vc-arrows-container {
    display: none;
  }
}
</style>
