import moment from "moment"
const state = () => ({
  title: null,
  loading: false,
  loader: false,
  progress: false,
  date: {
    year: moment().format("YYYY"),
    month: moment().format("MM"),
    day: moment().format("DD"),
  },
})

// getters
const getters = {}

// actions
const actions = {}

// mutations
const mutations = {
  changeTitle(state, title) {
    state.title = title
  },
  onLoading(state) {
    state.loading = true
  },
  offLoading(state) {
    state.loading = false
  },
  onProgress(state) {
    state.progress = true
  },
  offProgress(state) {
    state.progress = false
  },
  onLoader(state) {
    state.loader = true
  },
  offLoader(state) {
    state.loader = false
  },
  changeDate(state, date) {
    state.date = date
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
