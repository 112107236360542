import store from "@/store"
import numeral from "numeral"
export function formatNumber(number) {
  if (!number && number !== 0) {
    return ""
  }
  const num = numeral(number)
  if (store.state.settings.formatNumber) {
    return num.format("0,0[.]00")
  }
  return num.value()
}
