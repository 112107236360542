export default {
  data: () => {
    return {}
  },
  methods: {
    update(evt) {
      this.$emit("update", evt.target.value)
    },
  },
}
