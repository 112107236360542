import moment from "moment"

export default {
  data: () => {
    return {
      year: 0,
      month: 0,
      day: 0,
    }
  },
  computed: {
    monthIndex() {
      return this.month - 1
    },
    formatDate() {
      return moment({ year: this.year, month: this.monthIndex, day: this.day }).format("YYYY-MM-DD")
    },
    prevMonth() {
      return moment(this.formatDate).subtract(1, "M").format("YYYY-MM-DD")
    },
    nextMonth() {
      return moment(this.formatDate).add(1, "M").format("YYYY-MM-DD")
    },
    prevDate() {
      return moment(this.formatDate).subtract(1, "d").format("YYYY-MM-DD")
    },
    nextDate() {
      return moment(this.formatDate).add(1, "d").format("YYYY-MM-DD")
    },
  },
  methods: {
    setDate(date) {
      const currentDate = date ?? this.$route.query.date ?? moment().format("YYYY-MM-DD")
      // YYYY-MM-DD
      // 0123456789
      this.year = Number(currentDate.substr(0, 4))
      this.month = Number(currentDate.substr(5, 2))
      this.day = Number(currentDate.substr(8, 2))
      this.$store.commit("page/changeDate", { year: this.year, month: this.month, day: this.day })
    },
    movePrevMonth() {
      this.setDate(this.prevMonth)
      this.fetchData()
    },
    moveNextMonth() {
      this.setDate(this.nextMonth)
      this.fetchData()
    },
    selectMonth(selectedDate) {
      this.setDate(selectedDate)
      this.fetchData()
    },
    fetchData() {},
  },
  mounted() {
    this.setDate()
  },
}
