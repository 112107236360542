<template>
  <div class="ui modal" :class="classes" ref="modal">
    <!--    <i class="close icon"></i>-->
    <div class="header" v-if="headerText && !mini">
      {{ headerText }}
    </div>
    <div class="content" :class="{ scrolling: scrolling }">
      <div v-if="headerText && mini" class="ui header">{{ headerText }}</div>
      <slot></slot>
    </div>
    <div class="actions">
      <slot name="actions">
        <m-btn @click="close">닫기</m-btn>
      </slot>
      <!--
      <m-btn deny @click="handleDeny" :disabled="loading" v-if="deny">취소</m-btn>
      <m-btn approve @click="handleApprove" :loading="loading" :disabled="loading"><i class="check icon"></i>확인</m-btn>
      -->
    </div>
  </div>
</template>

<script>
import MBtn from "@/components/common/MBtn"
export default {
  name: "MDialog",
  components: { MBtn },
  props: {
    headerText: {
      type: String,
      default: null,
    },
    value: Boolean,
    basic: Boolean,
    mini: Boolean,
    fullscreen: Boolean,
    overlay: Boolean,
    allowMultiple: Boolean,
    scrolling: Boolean,
  },
  data() {
    return {}
  },
  computed: {
    classes() {
      return [{ mini: this.mini }, { basic: this.basic }, { fullscreen: this.fullscreen }, { overlay: this.overlay }]
    },
  },
  watch: {
    value(value) {
      if (value) {
        $(this.$refs.modal).modal("show")
      } else {
        $(this.$refs.modal).modal("hide")
      }
    },
  },
  created() {},
  mounted() {
    const self = this
    $(this.$refs.modal).modal({
      closeIcon: false,
      closable: false,
      allowMultiple: this.allowMultiple,
      transition: {
        showMethod: "fade up",
        hideMethod: "fade down",
      },
      onHide: function () {
        self.$emit("input", false)
      },
    })
  },
  methods: {
    close() {
      $(this.$refs.modal).modal("hide")
    },
  },
}
</script>

<style scoped></style>
